import React from "react";
import { Button, Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const pages = generatePages(currentPage, totalPages);

  const handlePageClick = (page: number) => {
    // @ts-ignore
    if (page !== currentPage && page !== "...") {
      setCurrentPage(page);
    }
  };

  return (
    <Box sx={{ display: "inline-flex", mt: 2 }}>
      {/* Pagination */}
      <Button
        variant="contained"
        disabled={currentPage === 1}
        onClick={() => handlePageClick(1)}>
        <FastRewindIcon />
      </Button>
      <Button
        variant="contained"
        disabled={currentPage === 1}
        onClick={() => handlePageClick(currentPage - 1)}>
        <ChevronLeftIcon />
      </Button>
      {pages.map((page, index) => (
        <Button
          key={index}
          variant="contained"
          disabled={page === currentPage || page === "..."}
          onClick={() => handlePageClick(page as number)}
          sx={{
            fontWeight: page === currentPage ? "bold" : "normal",
            backgroundColor: page === currentPage ? "blue" : "primary",
            color: page === "..." ? "grey" : "white",
          }}>
          {page}
        </Button>
      ))}
      <Button
        variant="contained"
        disabled={currentPage === totalPages}
        onClick={() => handlePageClick(currentPage + 1)}>
        <ChevronRightIcon />
      </Button>
      <Button
        variant="contained"
        disabled={currentPage === totalPages}
        onClick={() => handlePageClick(totalPages)}>
        <FastForwardIcon />
      </Button>
    </Box>
  );
};

export default Pagination;

// Helper function to generate pagination pages
function generatePages(
  currentPage: number,
  totalPages: number
): (number | string)[] {
  const pages: (number | string)[] = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const start = Math.max(2, currentPage - 2);
    const end = Math.min(totalPages - 1, currentPage + 2);

    pages.push(1);
    if (start > 2) pages.push("...");
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    if (end < totalPages - 1) pages.push("...");
    pages.push(totalPages);
  }

  return pages;
}
