// services/CartService.ts
export interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  supplier: number | string;
  stock?: number;
  thumb?: string;
  sku?: string;
  product_type?: string;
  product_option?: any;
  product_parent?: number | string;
}

const CART_KEY = "shopping_cart";

export const CartService = {
  getOrderSupplier: (): string | null => {
    return localStorage.getItem("selectedOrderSupplier");
  },
  saveOrderSupplier: (id: string | null) => {
    if (id) {
      localStorage.setItem("selectedOrderSupplier", id);
    } else {
      localStorage.removeItem("selectedOrderSupplier");
    }
  },
  getPaymentCondition: (): string | null => {
    return localStorage.getItem("paymentCondition");
  },
  savePaymentCondition: (paymentCondition: string | null) => {
    if (paymentCondition) {
      localStorage.setItem("paymentCondition", paymentCondition);
    } else {
      localStorage.removeItem("paymentCondition");
    }
  },
  getCart: (): CartItem[] => {
    const cart = localStorage.getItem(CART_KEY);
    return cart ? JSON.parse(cart) : [];
  },

  saveCart: (cart: CartItem[]) => {
    localStorage.setItem(CART_KEY, JSON.stringify(cart));
  },

  addToCart: (newItem: CartItem) => {
    const orderSupplier = CartService.getOrderSupplier();
    const cart = CartService.getCart();
    if (cart.length === 0) {
      CartService.saveOrderSupplier(String(newItem.supplier));
    } else {
      if (orderSupplier && orderSupplier !== String(newItem.supplier)) {
        throw new Error(
          "Você não pode adicionar produtos de fornecedores diferentes. Limpe seu carrinho para iniciar uma nova ordem neste fornecedor."
        );
      }
    }
    const existingItem = cart.find((item) => item.id === newItem.id);

    if (existingItem) {
      existingItem.quantity += newItem.quantity;
    } else {
      cart.push(newItem);
    }

    CartService.saveCart(cart);
  },

  removeFromCart: (id: string) => {
    const cartItems = CartService.getCart();
    if (cartItems.length === 1) {
      CartService.saveOrderSupplier("");
    }
    // console.log("removeFromCart", cartItems);
    const cart = CartService.getCart().filter((item) => item.id !== id);
    CartService.saveCart(cart);
  },

  clearCart: () => {
    CartService.saveOrderSupplier("");
    localStorage.removeItem(CART_KEY);
  },

  updateQuantity: (id: string, quantity: number) => {
    const cart = CartService.getCart().map((item) => {
      if (item.id === id) {
        return { ...item, quantity };
      }
      return item;
    });
    CartService.saveCart(cart);
  },
};
