import React, { useEffect, useState } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Collapse,
  CircularProgress,
} from "@mui/material";
import { ItemsService } from "../services/ItemsService"; // Adjust the import path
import CloseIcon from "@mui/icons-material/Close";

type SidebarProps = {
  currentFilter: any;
  handleFilter: any;
  filteredCategories: any[];
  filteredSuppliers: any[];
  suppliers: any[];
  setFilteredSuppliers: any;
  loading: boolean;
};

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const {
    currentFilter,
    handleFilter,
    filteredCategories,
    filteredSuppliers,
    suppliers,
    setFilteredSuppliers,
    loading,
  } = props;
  const [suppliersCollapse, setSuppliersCollapse] = useState(true);
  const [catCollapse, setCatCollapse] = useState(true);
  const [supplierSearch, setSupplierSearch] = useState<string>("");

  const handleSupplierSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSupplierSearch(query);
    const filtered = suppliers.filter((supplier: any) =>
      supplier.name.toLowerCase().includes(query)
    );
    setFilteredSuppliers(filtered);
  };

  const handleProductSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();

    handleFilter({ name: `${query}` });
  };

  const renderProductSearch = () => (
    <TextField
      label="Buscar produtos"
      fullWidth
      size="small"
      sx={{ marginBottom: "16px" }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleProductSearch(e)
      }
    />
  );

  const buildCategoryTree = (categories: any[]) => {
    const categoryMap = new Map<string, any & { children?: any[] }>();

    // Initialize the map
    categories.forEach((category) => {
      categoryMap.set(category.id, { ...category, children: [] });
    });

    // Build the tree
    const roots: (any & { children: any[] })[] = [];
    categoryMap.forEach((category) => {
      if (category.parent) {
        const parent = categoryMap.get(category.parent);
        if (parent) {
          parent.children!.push(category);
        }
      } else {
        roots.push(category);
      }
    });

    return roots;
  };

  const renderCategoryList = (categories: any[]) => (
    <List component="nav">
      {categories.map((category) => (
        <React.Fragment key={category.id}>
          <ListItem
            onClick={() => handleFilter({ category: category.id })}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              color: "black", // Add this line to set the color to black
            }}>
            <ListItemText primary={category.name} />
            {currentFilter.category === category.id && (
              <CloseIcon sx={{ marginLeft: "auto" }} />
            )}
          </ListItem>
          {category.children && category.children.length > 0 && (
            <Box pl={2}>{renderCategoryList(category.children)}</Box>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }
  const categoryTree = buildCategoryTree(filteredCategories);

  return (
    <Box sx={{ width: "100%", padding: "16px", borderRight: "1px solid #ddd" }}>
      {renderProductSearch()}
      <Box
        sx={{
          marginBottom: "16px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "8px",
        }}>
        <Typography
          variant="h6"
          gutterBottom
          onClick={() => setSuppliersCollapse(!suppliersCollapse)}
          sx={{ cursor: "pointer", marginBottom: "16px" }}>
          Fornecedores
        </Typography>
        <Collapse in={suppliersCollapse}>
          <TextField
            label="Filtrar fornecedores"
            fullWidth
            value={supplierSearch}
            onChange={handleSupplierSearch}
            size="small"
            sx={{ marginBottom: "16px" }}
          />
          <List component="nav" sx={{ marginTop: "16px" }}>
            {filteredSuppliers.map((supplier: any) => (
              <ListItem
                key={supplier.id}
                onClick={() => handleFilter({ supplier: supplier.id })}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  color: "black", // Add this line to set the color to black
                }}>
                <ListItemText primary={supplier.name} />
                {currentFilter.supplier === supplier.id && (
                  <CloseIcon sx={{ marginLeft: "auto" }} />
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Box>
      <Box
        sx={{
          marginBottom: "16px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "8px",
        }}>
        <Typography
          variant="h6"
          gutterBottom
          onClick={() => setCatCollapse(!catCollapse)}
          sx={{ cursor: "pointer", marginBottom: "16px" }}>
          Categorias
        </Typography>
        <Collapse in={catCollapse}>{renderCategoryList(categoryTree)}</Collapse>
      </Box>
    </Box>
  );
};

export default Sidebar;
