import {
  Box,
  Grid2,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import configs from "../config";
import AddToCartButton from "./AddToCartButton";
import Pagination from "./Pagination";
import { formatToBrazilianCurrency } from "../lib/util";

type ProductListProps = {
  products: any;
  loading: boolean;
  error: string | null;
  currentPage: number;
  setCurrentPage: any;
  totalPages: number;
};

const ProductList = (props: ProductListProps) => {
  const { products, loading, error, currentPage, setCurrentPage, totalPages } =
    props;
  const accessToken = localStorage.getItem("accessToken");
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Grid2 container spacing={2}>
        {products.map((product: any) => (
          <Grid2
            size={{
              xs: 12,
              sm: 6,
              md: 4,
              lg: 3,
            }}
            key={product.id}>
            <Card>
              <CardActionArea component="a" href={`/product/${product.id}`}>
                <CardMedia
                  component="img"
                  height="140"
                  width="100%"
                  image={configs.uploadsUrl + product.thumb}
                  alt={product.name}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 16 }}>
                    {product.name} - (SID: {product.supplier})
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {product.product_type === "variable" ? (
                      <>{product.price_range}</>
                    ) : (
                      <>{formatToBrazilianCurrency(product.price)}</>
                    )}
                  </Typography>
                  {/* {
                    product.product_type === "variable" && productVariations &&
                    productVariations.map((variation:any) => {
                        return (
                            <Tooltip title={variation.name} key={variation.id}
                            >
                                <Box>
                                    <Typography variant="body2" color="textSecondary"
                                    >{variation.name} </Typography>
                                </Box>
                            </Tooltip>
                        );
                    })
                } */}
                </CardContent>
              </CardActionArea>
            </Card>
            {accessToken ? (
              <AddToCartButton
                product={product}
                disabled={
                  product.product_type === "simple" && product.stock === 0
                }
              />
            ) : (
              <AddToCartButton product={product} disabled={true} />
            )}
          </Grid2>
        ))}
      </Grid2>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
        width="100%">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </>
  );
};

export default ProductList;
