import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Grid2,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import { ItemsService } from "../services/ItemsService"; // Adjust the import path
import configs from "../config";
import AddToCartButton from "../components/AddToCartButton";
import ProductList from "../components/ProductList";

type Product = {
  id: string;
  name: string;
  image: string; // Assuming products have an image
  price: number;
};

const Supplier: React.FC = () => {
  const { id } = useParams();
  const [products, setProducts] = useState<Product[] | any[]>([]);
  const [supplier, setSupplier] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const itemsService = new ItemsService();
      try {
        // Fetch products for the supplier
        const response = await itemsService.getItems(
          { collection: "products", meta: null, schema: null },
          {
            fields: ["id", "name", "thumb", "price"],
            filter: {
              supplier: {
                name: {
                  _contains: "lux",
                },
              },
            },
          }
        );
        setProducts(response);
      } catch (error) {
        setError("Error fetching products");
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSupplier = async () => {
      const itemsService = new ItemsService();
      try {
        const response = await itemsService.getItem(
          { collection: "suppliers", meta: null, schema: null },
          id as string
        );
        setSupplier(response.name);
      } catch (error) {
        setError("Error fetching supplier");
        console.error("Error fetching supplier:", error);
      }
    };

    fetchSupplier();

    fetchProducts();
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box flex="1" sx={{ mt: 3, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Produtos do Fornecedor: {supplier}
      </Typography>
      <ProductList filter={{ supplier: id }} />
    </Box>
  );
};

export default Supplier;
