import React, { createContext, useContext, useState, useEffect } from "react";
import { CartService, CartItem } from "../services/CartService";

interface CartContextProps {
  cart: CartItem[];
  addItem: (item: CartItem) => void;
  removeItem: (id: string) => void;
  clearCart: () => void;
  updateItemQuantity: (id: string, quantity: number) => void;
}

export const CartContext = createContext<CartContextProps | undefined>(
  undefined
);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cart, setCart] = useState<CartItem[]>(CartService.getCart());

  useEffect(() => {
    CartService.saveCart(cart);
  }, [cart]);

  const addItem = (item: CartItem) => {
    CartService.addToCart(item);
    setCart(CartService.getCart());
  };

  const removeItem = (id: string) => {
    CartService.removeFromCart(id);
    setCart(CartService.getCart());
  };

  const clearCart = () => {
    CartService.clearCart();
    setCart([]);
  };

  const updateItemQuantity = (id: string, quantity: number) => {
    if (quantity <= 0) {
      quantity = 1;
    }
    CartService.updateQuantity(id, quantity);
    setCart(CartService.getCart());
  };

  return (
    <CartContext.Provider
      value={{ cart, addItem, removeItem, clearCart, updateItemQuantity }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextProps => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
