import { readUser, readUsers, withToken } from "@directus/sdk";
import { SdkClient } from "../types/client";
import { drcClient } from "../App";

class UsersService {
  directusClient: SdkClient;
  constructor() {
    this.directusClient = drcClient.directusClient;
  }

  async getUsers() {
    const accessToken = localStorage.getItem("accessToken");
    const users = await this.directusClient.request(
      withToken(String(accessToken), readUsers())
    );
    return users;
  }
  async getUser(id: string) {
    const accessToken = localStorage.getItem("accessToken");
    const user = await this.directusClient.request(
      withToken(String(accessToken), readUser(id))
    );
    return user;
  }
}

export default UsersService;
