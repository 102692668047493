import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { DirectusClient } from "../lib/DirectusClient";
import { logout } from "@directus/sdk";
import configs from "../config";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
const pages = [
  {
    name: "Catálogo",
    url: "/",
  },
  {
    name: "Carrinho",
    url: "/cart",
  },
];
const settings = [
  {
    name: "Meus Pedidos",
    url: "/my-orders",
  },
  {
    name: "Sair",
    url: "/logout",
  },
];
type NavbarProps = {
  fetchMyOrders: any;
  fetchProducts: any;
  drcClient: any;
};

function ResponsiveAppBar({
  fetchMyOrders,
  fetchProducts,
  drcClient,
}: NavbarProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const projectInfo = localStorage.getItem("projectInfo");
  const projectName = projectInfo
    ? JSON.parse(projectInfo).project_name
    : "Nome do Projeto";
  const projectLogo = projectInfo
    ? JSON.parse(projectInfo).project_logo
    : "logo.png";
  const projectColor = projectInfo
    ? JSON.parse(projectInfo).project_color
    : "#000000";
  const client = drcClient.directusClient;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const doLogout = async () => {
    // const authLogin = await client.request(login(email, password));
    try {
      await client.request(logout(refreshToken as string, "json"));
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      console.log("Logged out");
      window.location.href = "/login";
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: projectColor, pl: 3, pr: 3 }}>
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
            to="/"
            onClick={fetchProducts}>
            <img
              src={configs.uploadsUrl + projectLogo}
              alt="Logo"
              style={{
                height: "40px",
                marginRight: "10px",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                lineHeight: "20px",
                wordBreak: "break-word",
                maxWidth: "200px",
              }}>
              {projectName}
            </Typography>
          </Link>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit">
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}>
            {pages.map((page, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  handleCloseNavMenu();
                  navigate(page.url);
                }}>
                <Typography sx={{ textAlign: "center" }}>
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
            to="/"
            onClick={fetchProducts}>
            <img
              src={configs.uploadsUrl + projectLogo}
              alt="Logo"
              style={{
                height: "40px",
                marginRight: "10px",
              }}
            />
          </Link>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page, i) => (
            <Button
              key={i}
              onClick={() => {
                handleCloseNavMenu();
                navigate(page.url);
              }}
              sx={{ my: 2, color: "white", display: "block" }}>
              {page.name}
            </Button>
          ))}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Minha Conta">
            <>
              <IconButton
                title="Carrinho"
                onClick={() => navigate("/cart")}
                sx={{ p: 0, mr: 1 }}>
                <ShoppingCart sx={{ color: "white" }} />
              </IconButton>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Cliente" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            {settings.map((setting, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  handleCloseUserMenu();
                  // navigate(setting.url);
                  if (setting.name === "Sair") {
                    if (accessToken) {
                      doLogout();
                    } else {
                      navigate("/login");
                    }
                  } else if (setting.name === "Meus Pedidos") {
                    fetchMyOrders();
                    navigate(setting.url);
                  } else {
                    navigate(setting.url);
                  }
                }}>
                {setting.name === "Sair" && !accessToken ? (
                  <Typography sx={{ textAlign: "center" }}>Login</Typography>
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    {setting.name}
                  </Typography>
                )}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
