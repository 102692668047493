import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import configs from "../config";
import AddToCartButton from "../components/AddToCartButton";
import { formatToBrazilianCurrency } from "../lib/util";

type ProductProps = {
  setProduct: any;
  setError: any;
  setLoading: any;
  loading: boolean;
  error: string | null;
  product: any;
  productVariations: any;
  fetchProduct: any;
  setSelectedVariant: any;
  selectedVariant: any;
};

const Product: React.FC<ProductProps> = (props: ProductProps) => {
  const {
    loading,
    error,
    product,
    fetchProduct,
    productVariations,
    selectedVariant,
    setSelectedVariant,
  } = props;
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();

  useEffect(() => {
    fetchProduct(id);
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box padding="16px" flex="1">
      <Typography variant="h4" gutterBottom>
        {product.name}
      </Typography>

      <Box display="flex" flexDirection="row">
        <Box flex={1}>
          <img
            src={configs.uploadsUrl + product.thumb}
            alt={product.name}
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </Box>

        <Box flex={2} paddingLeft="16px">
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: 800 }}
            gutterBottom>
            {product.product_type === "variable" ? (
              <>{product.price_range}</>
            ) : (
              <>
                {product.price
                  ? formatToBrazilianCurrency(product.price)
                  : product.price}
              </>
            )}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Fornecedor: </strong>
            {product.supplier_name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Categoria:</strong> {product.category_name}
          </Typography>

          {typeof product.stock === "number" &&
            product.product_type === "simple" && (
              <Typography variant="body1" gutterBottom>
                <strong>Estoque:</strong> {product.stock}
              </Typography>
            )}
          {product.dimensions && (
            <Typography variant="body1" gutterBottom>
              <strong>Dimensões:</strong> {product.dimensions}
            </Typography>
          )}

          {/* <Typography variant="body1" gutterBottom>
            <strong>Tipo:</strong> {product.product_type}
          </Typography> */}
          {product.weight && (
            <Typography variant="body1" gutterBottom>
              <strong>Peso:</strong> {product.weight} kg
            </Typography>
          )}
          {product.short_description && (
            <Typography variant="body2">
              <strong>Descrição Breve:</strong>
              <br />
              {product.short_description}
            </Typography>
          )}

          {product.description && (
            <Typography variant="body2" paragraph>
              <strong>Descrição Completa:</strong>
              <br />
              {product.description}
            </Typography>
          )}

          {/* Product Variations */}
          {productVariations && productVariations.length > 0 && (
            <Box marginTop="16px">
              <Typography variant="h6">Opções:</Typography>
              <List>
                {productVariations.map((variation: any, index: any) => (
                  <ListItem
                    key={index}
                    onClick={() => setSelectedVariant(variation)}
                    style={{
                      cursor: "pointer",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      marginBottom: "8px",
                      padding: "8px 16px",
                      backgroundColor:
                        selectedVariant.id === variation.id
                          ? "#f5f5f5"
                          : "transparent",
                    }}>
                    <ListItemText
                      primary={`${variation.name} `}
                      secondary={
                        `${formatToBrazilianCurrency(variation.price)}` +
                        (typeof variation.stock === "number"
                          ? ` | Estoque: ${variation.stock}`
                          : "")
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          <Box marginTop="16px">
            {accessToken ? (
              <AddToCartButton
                product={product}
                selectedVariant={selectedVariant}
                disabled={product.stock === 0}
              />
            ) : (
              <AddToCartButton product={product} disabled={true} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Product;
