import React, { useEffect } from "react";
import { Box, Grid2 } from "@mui/material";
import Sidebar from "../components/Sidebar";
import { CartProvider } from "../context/CartContext";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  appLogic: any;
}

const Layout: React.FC<Props> = ({ children, appLogic }) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (location.pathname !== "/" && !location.pathname.includes("product/")) {
  //     navigate("/");
  //   }
  // }, [appLogic.currentFilter]);
  return (
    <CartProvider>
      <Box>
        <Grid2 container spacing={2}>
          {/* Left Sidebar (Suppliers Filter) */}
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 3,
            }}>
            <Sidebar
              currentFilter={appLogic.currentFilter}
              handleFilter={appLogic.handleFilter}
              loading={appLogic.loading}
              suppliers={appLogic.suppliers}
              filteredSuppliers={appLogic.filteredSuppliers}
              setFilteredSuppliers={appLogic.setFilteredSuppliers}
              filteredCategories={appLogic.filteredCategories}
            />
          </Grid2>

          {/* Right Body Section (Product Grid2) */}
          {children}
        </Grid2>
      </Box>
    </CartProvider>
  );
};

export default Layout;
