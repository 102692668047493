import { readItem, readItems, withToken, createItem } from "@directus/sdk";
import { QueryParams, SdkClient } from "../types/client";
import type { Collection } from "@directus/types";
import { drcClient } from "../App";

class ItemsService {
  directusClient: SdkClient;
  constructor() {
    this.directusClient = drcClient.directusClient;
  }
  async countAllItems(collection: Collection, queryParams: QueryParams) {
    const accessToken = localStorage.getItem("accessToken") || "";

    const collectionName =
      typeof collection === "string" ? collection : collection.collection;

    let result;
    if (accessToken) {
      result = await this.directusClient.request(
        withToken(
          accessToken,
          readItems(collectionName, {
            fields: ["id"],
            limit: -1,
            ...queryParams,
          })
        )
      );
    } else {
      result = await this.directusClient.request(
        readItems(collectionName, {
          fields: ["id"],
          limit: -1,
          ...queryParams,
        })
      );
    }

    return result.length;
  }

  async getItems(collection: Collection, queryParams: QueryParams) {
    const accessToken = localStorage.getItem("accessToken") || "";
    const collectionName =
      typeof collection === "string" ? collection : collection.collection;

    let result;
    if (accessToken) {
      result = await this.directusClient.request(
        withToken(accessToken, readItems(collectionName, queryParams))
      );
    } else {
      result = await this.directusClient.request(
        readItems(collectionName, queryParams)
      );
    }

    return result;
  }

  async getItem(collection: Collection, id: string | number) {
    const accessToken = localStorage.getItem("accessToken") || "";

    const collectionName =
      typeof collection === "string" ? collection : collection.collection;

    let result;

    if (accessToken) {
      result = await this.directusClient.request(
        withToken(accessToken, readItem(collectionName, id))
      );
    } else {
      result = await this.directusClient.request(readItem(collectionName, id));
    }
    return result;
  }

  async createItem(collection: Collection, item: any) {
    const accessToken = localStorage.getItem("accessToken") || "";

    const collectionName =
      typeof collection === "string" ? collection : collection.collection;

    let result;

    if (accessToken) {
      result = await this.directusClient.request(
        withToken(accessToken, createItem(collectionName, item))
      );
    } else {
      result = await this.directusClient.request(
        createItem(collectionName, item)
      );
    }
    return result;
  }
}

export { ItemsService };
