import { readItem, refresh, login, withToken } from "@directus/sdk";
import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DirectusClient } from "../lib/DirectusClient"; // Adjust the import path

type LoginProps = {
  drcClient: any;
};
const Login: React.FC<LoginProps> = ({ drcClient }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();
  const client = drcClient.directusClient;

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Perform login
      const authLogin = await client.request(
        login(email, password, { mode: "json" })
      );
      const accessToken = authLogin.access_token;
      const refreshToken = authLogin.refresh_token;
      console.log("handleLogin", JSON.stringify(authLogin));
      // console.log(
      //   " ",
      //   await client.request(
      //     withToken(accessToken, refresh("json", refreshToken))
      //   )
      // );
      localStorage.setItem("accessToken", accessToken as string);
      localStorage.setItem("refreshToken", refreshToken as string);
      //   console.log("setToken", await client.setToken(accessToken as string));
      //   const authData = await client.request(refresh("json", refreshToken));
      //   console.log({ authData });
      window.location.href = "/";
      // Handle successful login
      setSuccess("Login successful!");
      // fetchProducts();

      //   navigate("/home"); // Redirect to a different page on success
    } catch (err) {
      // Handle login error
      setError("Login failed. Please check your credentials and try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="16px">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>

      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth>
          {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
        </Button>
      </Box>

      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

      {success && (
        <Snackbar
          open={Boolean(success)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => setSuccess(null)}>
          <Alert onClose={() => setSuccess(null)} severity="success">
            {success}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Login;
