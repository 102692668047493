import { Box, Typography, CircularProgress, Modal } from "@mui/material";
import Pagination from "../components/Pagination";
import React from "react";
import { formatToBrazilianCurrency } from "../lib/util";

type MyOrdersProps = {
  orders: any;
  loading: boolean;
  error: string | null;
  currentPage: number;
  setCurrentPage: any;
  totalPages: number;
  paymentConditions: any;
};

const MyOrders = (props: MyOrdersProps) => {
  const [orderItems, setOrderItems] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectOrder = async (order: any) => {
    const orderItems = order.items;
    setOrderItems(order);
    handleOpen();
  };
  const {
    orders,
    loading,
    error,
    currentPage,
    setCurrentPage,
    totalPages,
    paymentConditions,
  } = props;

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }
  return (
    <Box padding="16px" flex="1">
      <Box
        component="table"
        width="100%"
        border="1px solid #e0e0e0"
        borderRadius="4px">
        <Box component="thead">
          <Box component="tr">
            <Box
              component="th"
              padding="8px"
              borderBottom="1px solid #e0e0e0"
              textAlign="left">
              Pedido
            </Box>
            <Box
              component="th"
              padding="8px"
              borderBottom="1px solid #e0e0e0"
              textAlign="left">
              Condição de pagamento
            </Box>
            <Box
              component="th"
              padding="8px"
              borderBottom="1px solid #e0e0e0"
              textAlign="left">
              Total
            </Box>
            <Box
              component="th"
              padding="8px"
              borderBottom="1px solid #e0e0e0"
              textAlign="left">
              Data
            </Box>
          </Box>
        </Box>
        <Box component="tbody">
          {orders && orders.length > 0 ? (
            orders.map((order: any) => (
              <Box
                component="tr"
                key={order.id}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                onClick={() => handleSelectOrder(order)}>
                <Box
                  component="td"
                  padding="8px"
                  borderBottom="1px solid #e0e0e0">
                  {order.id}
                </Box>
                <Box
                  component="td"
                  padding="8px"
                  borderBottom="1px solid #e0e0e0">
                  {
                    paymentConditions.find(
                      (condition: any) =>
                        condition.id === order.payment_condition
                    )?.name
                  }
                </Box>
                <Box
                  component="td"
                  padding="8px"
                  borderBottom="1px solid #e0e0e0">
                  {formatToBrazilianCurrency(order.total)}
                </Box>
                <Box
                  component="td"
                  padding="8px"
                  borderBottom="1px solid #e0e0e0">
                  {new Date(order.date_created).toLocaleDateString("pt-BR")}
                </Box>
              </Box>
            ))
          ) : (
            <Box component="tr">
              <Box component="td" colSpan={5} padding="8px" textAlign="center">
                Nenhum pedido encontrado.
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
        width="100%"
        marginTop="16px">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Itens do Pedido
          </Typography>

          {orderItems.items && orderItems.items.length > 0 ? (
            <Box
              component="table"
              width="100%"
              border="1px solid #e0e0e0"
              borderRadius="4px">
              <Box component="thead">
                <Box component="tr">
                  <Box
                    component="th"
                    padding="8px"
                    borderBottom="1px solid #e0e0e0"
                    textAlign="left">
                    Produto
                  </Box>
                  <Box
                    component="th"
                    padding="8px"
                    borderBottom="1px solid #e0e0e0"
                    textAlign="left">
                    Preço
                  </Box>
                  <Box
                    component="th"
                    padding="8px"
                    borderBottom="1px solid #e0e0e0"
                    textAlign="left">
                    Qtd.
                  </Box>
                  <Box
                    component="th"
                    padding="8px"
                    borderBottom="1px solid #e0e0e0"
                    textAlign="left">
                    Total
                  </Box>
                </Box>
              </Box>
              <Box component="tbody">
                {orderItems.items.map((item: any, i: number) => (
                  <Box component="tr" key={i}>
                    <Box
                      component="td"
                      padding="8px"
                      borderBottom="1px solid #e0e0e0">
                      {item.product}
                    </Box>
                    <Box
                      component="td"
                      padding="8px"
                      borderBottom="1px solid #e0e0e0">
                      {formatToBrazilianCurrency(item.price)}
                    </Box>
                    <Box
                      component="td"
                      padding="8px"
                      borderBottom="1px solid #e0e0e0">
                      {item.quantity}
                    </Box>
                    <Box
                      component="td"
                      padding="8px"
                      borderBottom="1px solid #e0e0e0">
                      {formatToBrazilianCurrency(item.price * item.quantity)}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            <Box component="tr">
              <Box component="td" colSpan={5} padding="8px" textAlign="center">
                Nenhum item encontrado.
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default MyOrders;
