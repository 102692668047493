import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ItemsService } from "../services/ItemsService";
import ProductList from "../components/ProductList";

type CategoryType = {
  id: string;
  name: string;
};

const Category: React.FC = () => {
  const { id } = useParams();
  const [products, setProducts] = useState<CategoryType[] | any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [category, setCategory] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const itemsService = new ItemsService();
      try {
        // Fetch products for the supplier
        const response = await itemsService.getItems(
          { collection: "products", meta: null, schema: null },
          { fields: ["id", "name", "price", "thumb"], filter: { category: id } }
        );
        setProducts(response);
      } catch (error) {
        setError("Error fetching products");
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

    const fetchCategory = async () => {
      const itemsService = new ItemsService();
      try {
        const response = await itemsService.getItem(
          { collection: "categories", meta: null, schema: null },
          id as string
        );
        setCategory(response.name);
      } catch (error) {
        setError("Error fetching category");
        console.error("Error fetching category:", error);
      }
    };

    fetchCategory();
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px">
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box padding="16px" flex="1">
      <Typography variant="h4" gutterBottom>
        Produtos na Categoria: {category}
      </Typography>
      <ProductList filter={{ category: id }} />
    </Box>
  );
};

export default Category;
