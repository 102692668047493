import React from "react";
import { Alert, Button, Snackbar, Tooltip } from "@mui/material";
import { useCart } from "../hooks/useCart";
import { CartItem, CartService } from "../services/CartService";

interface AddToCartButtonProps {
  product: {
    id: string;
    name: string;
    price: number;
    supplier: number | string;
    stock?: number;
    thumb?: string;
    sku?: string;
    product_type?: string;
  };
}

const AddToCartButton: React.FC<
  AddToCartButtonProps & { disabled?: boolean } & { selectedVariant?: any }
> = ({ product, disabled, selectedVariant }) => {
  const { addItem } = useCart();
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const projectInfo = localStorage.getItem("projectInfo");
  const projectColor = projectInfo
    ? JSON.parse(projectInfo).project_color
    : "#000000";

  const handleAddToCart = () => {
    const cartItem: CartItem = {
      id: product.id,
      supplier: product.supplier,
      name: product.name,
      price: product.price,
      stock: product.stock,
      quantity: 1, // Default quantity when adding
      thumb: product.thumb,
      product_type: product.product_type,
    };
    if (product.product_type !== "simple" && !selectedVariant) {
      window.location.href = `/product/${product.id}`;
      return;
    }
    try {
      if (
        product.product_type !== "simple" &&
        !selectedVariant.hasOwnProperty("id")
      ) {
        setError("Selecione uma variação para adicionar ao pedido.");
        return;
      }
      if (product.product_type !== "simple" && selectedVariant) {
        cartItem.id = selectedVariant.id;
        cartItem.price = selectedVariant.price;
        cartItem.stock = selectedVariant.stock;
        cartItem.name = `${product.name} - (${selectedVariant.name})`;
        cartItem.product_parent = product.id;
        addItem(cartItem);
      } else {
        addItem(cartItem);
      }
      setSuccess("Produto adicionado ao pedido.");
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <>
      {disabled ? (
        <Tooltip title="Não é possível adicionar, confira se o estoque é suficiente e se você está logado.">
          <span>
            <Button
              variant="contained"
              sx={{
                backgroundColor: projectColor,
              }}
              fullWidth
              disabled>
              Adicionar ao Pedido
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          sx={{
            backgroundColor: projectColor,
          }}
          fullWidth
          disabled={disabled}
          onClick={handleAddToCart}>
          Adicionar ao Pedido
        </Button>
      )}
      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          open={Boolean(success)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => setSuccess(null)}>
          <Alert onClose={() => setSuccess(null)} severity="success">
            {success}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddToCartButton;
