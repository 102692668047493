import { createDirectus, rest } from "@directus/sdk";
import type { SdkClient } from "../types/client";
import configs from "../config";

class DirectusClient {
  directusClient: SdkClient;
  constructor() {
    this.directusClient = createDirectus(configs.hostUrl).with(rest());
    // console.log(`DirectusClient: ${configs.hostUrl}`);
  }
}

export { DirectusClient };
