// Home.tsx
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Suppliers from "../components/Suppliers";
import ProductList from "../components/ProductList";
// import Body from '../components/Body'; // Grid of products
// import Slider from '../components/Slider'; // Featured products slider component

type HomeProps = {
  products: any;
  loading: boolean;
  error: string | null;
  currentPage: number;
  setCurrentPage: any;
  totalPages: number;
  fetchProducts: any;
};

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  useEffect(() => {
    props.fetchProducts();
  }, []);
  return (
    <Box flex="1" sx={{ mt: 3, padding: 3 }}>
      {/* <Slider /> */}
      {/* <Suppliers /> */}
      <ProductList
        products={props.products}
        loading={props.loading}
        error={props.error}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        totalPages={props.totalPages}
      />
    </Box>
  );
};

export default Home;
